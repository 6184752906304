.list-demo {
    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .product-description {
        margin: 0 0 1rem 0;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    .product-list-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .product-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 0.5rem 0;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            align-self: flex-end;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: 0.5rem;
        }
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    .product-grid-item {
        margin: 0.5em;
        border: 1px solid #dee2e6;

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 75%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;
            align-items: center;

            img {
                width: 75%;
                margin: 2rem 0;
            }

            .product-list-detail {
                text-align: center;
            }

            .product-price {
                align-self: center;
            }

            .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.table-demo {
    .p-progressbar {
        height: 0.5rem;
        background-color: #d8dadc;

        .p-progressbar-value {
            background-color: #607d8b;
        }
    }

    .p-datatable .p-column-filter {
        display: none;
    }

    .table-header {
        font-size: 1.25rem;
        display: flex;
        justify-content: space-between;
    }

    .p-datatable.p-datatable-customers {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead > tr > th {
            text-align: left;
        }

        .p-datatable-tbody > tr > td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
        display: none;
    }

    .customer-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-qualified {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-unqualified {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-negotiation {
            background: #feedaf;
            color: #8a5340;
        }

        &.status-new {
            background: #b3e5fc;
            color: #23547b;
        }

        &.status-renewal {
            background: #eccfff;
            color: #694382;
        }

        &.status-proposal {
            background: #ffd8b2;
            color: #805b36;
        }
    }

    .p-progressbar-value.ui-widget-header {
        background: #607d8b;
    }

    @media (max-width: 640px) {
        .p-progressbar {
            margin-top: 0.5rem;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .orders-subtable {
        padding: 1rem;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    .order-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.order-delivered {
            background: #c8e6c9;
            color: #256029;
        }

        &.order-cancelled {
            background: #ffcdd2;
            color: #c63737;
        }

        &.order-pending {
            background: #feedaf;
            color: #8a5340;
        }

        &.order-returned {
            background: #eccfff;
            color: #694382;
        }
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    > td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }
                    }
                }

                colgroup {
                    display: none;
                }
            }

            .p-datatable-header {
                .table-header {
                    flex-direction: column !important;
                }
            }
        }
    }
}

.crud-demo {
    .p-toolbar {
        flex-wrap: wrap;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    /* Responsive */
    .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    border-bottom: 1px solid var(--surface-d);
                    > td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: 0.4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -0.4rem 1rem -0.4rem -0.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: 0.5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.docs {
    i:not([class~="pi"]) {
        background-color: transparent;
        color: #2196f3;
        font-family: Monaco, courier, monospace;
        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        padding: 0 4px;
        letter-spacing: 0.5px;
        font-weight: 600;
        margin: 0 2px;
        display: inline-flex;
    }

    a {
        font-weight: 500;
    }
}

pre[class*="language-"] {
    &:before,
    &:after {
        display: none !important;
    }

    code {
        border-left: 6px solid var(--surface-border) !important;
        box-shadow: none !important;
        background: var(--surface-ground) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;
    }

    .token {
        &.tag,
        &.keyword {
            color: #2196f3 !important;
        }

        &.attr-name,
        &.attr-string {
            color: #2196f3 !important;
        }

        &.attr-value {
            color: #4caf50 !important;
        }

        &.punctuation {
            color: var(--text-color);
        }

        &.operator,
        &.variable,
        &.string {
            background: transparent;
        }
    }
}

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
    line-height: 1;
}

.layout-logIn {
    padding-top: 7rem;
    width: 50%;
}

.container-fluid {
    max-width: 1820px;
    margin: 0 auto;
    width: 100%;
    padding: 0 10%;
}

.spinner {
    position: relative;
    .p-progress-spinner {
        position: fixed;
        left: 45%;
        z-index: 99999;
    }
}
.inner-spinner {
    .p-progress-spinner {
        position: fixed;
        left: 55%;
        z-index: 99999;
    }
}

.ToastMessage {
    z-index: 10000 !important;
}

.rp-image {
    position: relative !important;
}

@media screen and (max-width: 960px) {
    .customized-timeline {
        .p-timeline-event:nth-child(even) {
            flex-direction: row !important;

            .p-timeline-event-content {
                text-align: left !important;
            }
        }

        .p-timeline-event-opposite {
            flex: 0;
        }

        .p-card {
            margin-top: 1rem;
        }
    }
}

// canvas {
//     width: 100%;
//     height: 15vw;
//     object-fit: cover;
// }

.result {
    padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* QuestionCount Component */
.questionCount {
    padding: 1.5rem 2.5rem 0;
    font-size: 14px;
}

/* Question Component */
.question {
    margin: 0;
    padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
    border-top: 1px solid #eee;
}

.answerOption:hover {
    background-color: #eefbfe;
}

.radioCustomButton {
    position: absolute;
    width: auto;
    opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.radioCustomLabel {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1.5rem 2.5rem 1.5rem 6rem;
    font-size: 16px;
    line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
    position: absolute;
    top: 20px;
    left: 38px;
    width: 28px;
    height: 28px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 50%;
    transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
    content: "";
    background: #8bc53f url("./assets/icon-check.svg") no-repeat;
    background-size: 27px;
    border-color: #8bc53f;
}

/* Animation */
.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-appear {
    opacity: 0;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.carousel-demo .product-item .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.carousel-demo .car-buttons {
    justify-content: right;
}

.PowerBIEmbed {
    .header {
        background: #3476ae 0 0 no-repeat padding-box;
        border: 1px solid #707070;
        height: 55px;
        left: 0;
        top: 0;
    }

    .displayMessage {
        color: #000000;
        font: normal 22px/27px Segoe UI;
        letter-spacing: 0;
        margin-top: 1%;
        opacity: 1;
        text-align: center;
    }

    .hr {
        border: 1px solid #e0e0e0;
        opacity: 1;
    }

    .controls {
        margin-top: 1%;
        text-align: center;
    }

    .footer {
        background: #eef3f8 0 0 no-repeat padding-box;
        bottom: 0;
        height: 39px;
        opacity: 1;
        position: absolute;
        width: 100%;
    }

    .footer-text {
        font: Regular 16px/21px Segoe UI;
        height: 21px;
        letter-spacing: 0;
        margin-top: 9px;
        opacity: 1;
        position: relative;
        text-align: center;
        width: 100%;
    }

    .footer-text > a {
        color: #278ce2;
        font: Regular 16px/21px Segoe UI;
        letter-spacing: 0;
        text-decoration: underline;
    }

    .title {
        color: #ffffff;
        font: Bold 22px/27px Segoe UI;
        letter-spacing: 0;
        margin: 13px;
        margin-left: 36px;
        opacity: 1;
        text-align: left;
    }

    button {
        background: #337ab7;
        border: 0;
        border-radius: 5px;
        color: #ffffff;
        font-size: medium;
        height: 35px;
        margin-right: 15px;
        width: 150px;
    }

    button:onfocus {
        outline: none;
    }

    iframe {
        border: none;
    }

    .report-style-class {
        height: 69vh;
        margin: 1% auto;
        width: 100%;
    }
}

// .sc-iwsKbI > div:nth-child(3) {
//     width: 100% !important;
// }

.fixedScroll {
    overflow: scroll;
    width: 100%;
    max-height: 70vh;
}

.eKqwfA {
    background: rgba(0, 0, 0, 0.5) !important;
}

.ConfirmOTP .p-dialog-header-icons {
    display: none !important;
}

.inValidRadio {
    .p-radiobutton-box {
        border: 2px solid #c1331a !important;
    }
    .p-highlight {
        background-color: #c1331a !important;
    }
}

.validRadio {
    margin-bottom: 0.5rem;
    .p-radiobutton-box {
        border: 2px solid #44c11a !important;
    }
    .p-highlight {
        background-color: #4caf50 !important;
    }
}

.zoomImage {
    height: 300px !important;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.holder {
    overflow-y: scroll;
    height: 400px;
}

.holder ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.holder ul li {
    padding: 10px 0px;
}
.holder ul li a {
    color: var(--text-color);
    text-decoration: none;
}
.vwg-data-table {
    font-size: 1.4rem;
    font-family: Delivery;
    .data-column {
        word-break: break-word;
    }

    &.p-datatable {
        .p-datatable-thead {
            .frozen-column {
                position: sticky;
                left: 0;
                background-color: #fff;
            }
            tr th {
                background-color: #fff;
                border-bottom: 2px solid var(--color-dark-border);
                font-weight: bold;
                padding: 0 1rem !important;
                // padding: 0;
                height: 4rem;
            }
        }
        .p-datatable-tbody > tr {
            position: relative;
            &:nth-child(even) {
                background-color: #f2f2f2;
            }
            &:nth-child(odd) {
                background-color: #fdfdfd;
            }
            .frozen-column {
                position: sticky;
                left: 0;
                background-color: inherit;
            }
            td {
                padding: 1.5rem;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }
        }
        .pi {
            font-size: 1.6rem;
        }
        .p-sortable-column {
            &.p-highlight {
                background-color: #fff;
                color: var(--color-danger);
                .p-sortable-column-icon {
                    color: var(--color-danger);
                }
            }
            &:focus {
                box-shadow: none;
            }
        }
        .p-paginator {
            display: flex;
            padding: 1.5rem 0;
            align-items: center;
            font-family: "Delivery";
            .p-dropdown {
                height: 3rem;
                width: 5rem;
                margin-right: 1rem;
                margin-left: 0;
            }

            .p-dropdown-trigger {
                height: 3rem;
            }
            .p-paginator-current {
                margin-right: auto;
                margin-left: 0;
            }
            .p-paginator-pages {
                .p-paginator-page {
                    &.p-highlight {
                        background: var(--color-danger);
                        border-color: var(--color-danger);
                        color: #fff;
                    }
                }
            }

            .p-link {
                &:focus {
                    box-shadow: 0 0 0 0.2rem var(--color-light-danger);
                }
            }

            &.p-paginator-bottom {
                border: none;
                padding-bottom: 0;
            }

            * {
                font-size: 1.4rem;
            }
        }
    }
}

// .p-datatable-scrollable-header-box {
//     margin-right: 0px !important;
//     overflow-y: scroll !important;
// }

// .p-datatable-scrollable-body {
//     margin-right: 0px !important;
//     overflow-y: scroll !important;
// }

// .p-datatable-scrollable-header {
//     width: 100%;
// }
.w-v-100 {
    width: 100%;
}
